.content-div{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content:center ;
    align-content: center;
    align-self: center;
    color: #E4FBFF;
}
.table{
    width:40vw;
    text-align: left;
}
.h2-div{
    width: 39vw;
    margin-left: 5px;
}
.para-about{
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.15rem;
    color:#E4FBFF ;
    text-align: center;
}

@media only screen and (max-width: 450px) {
    .table{
        width:59vw;
    }
    .h2-div{
        width: 58vw;
        text-align: center;
        margin-left: 0;
    }
}