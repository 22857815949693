#nav-bar{
    backdrop-filter: blur(3px) saturate(168%);
    -webkit-backdrop-filter: blur(3px) saturate(168%);
    background-color: rgba(17, 25, 40, 0.47);
    border-radius: 8px;
    font-weight: 600;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    width: 90vw;
    margin-top: 15px;
    margin-left: 5vw;
    margin-right: 5vw;
}
#iciss-logo{
    max-width: 65px;
    max-height: 65px;
    border-radius: 5px;
}
@media only screen and (max-width: 450px) {
    #nav-bar{
        padding:20px;
    }
}