.footer{
    background-color: #E4FBFF; 
    height: 27vh;
}
.footer-items{
    padding:45px;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    color:#7868E6 ;
    font-weight: 500;
    font-size: 1.2rem;
}
.footertext{
    padding-bottom: 20px;
    text-align: center;
    font-size: 0.8rem;
}
@media only screen and (max-width: 450px) {
    .footer-items{
        font-size: 0.6rem;
        padding: 15px;
    }
    .footertext{
        margin-top:15px;
        font-size: 0.5rem;
    }
}