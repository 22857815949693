.professor-card{
    display: flex;
    align-items: center;
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    /* background-color: #1e0149; */
    font-family: 'Poppins',sans-serif;
    text-align: center;
    color: #EDEEF7;
    /* width:45vw; */
    /*border-radius: 8px;*/
    width: 45vw;
    padding: 10px;
    max-height: fit-content;
    border-bottom: solid #edeef74c 1px;
}
.name{
    font-size: 1rem;
    font-weight: 500;
    margin: 2px;
}
.description{
    font-style: italic;
    font-weight: 400;
    font-size: 0.7rem;
    margin: 2px;
    margin-left: 7px;
}
@media screen and (max-width: 768px){
    .professor-card{
        width: 80vw;
    }
    
}