/* CSS file for About Conference Card */
.about-conf-main{
    background-color: #1e0149;
    padding: 83px;
    width:80vw;
    border-radius: 8px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    color:#EDEEF7;
    text-align:left;
    margin: 30px;
}
@media only screen and (max-width: 450px) {
    .about-conf-main{
        margin: 5px;
        padding: 23px;
    }
}
