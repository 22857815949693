@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Quicksand:wght@400;500;600;700&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: 'Quicksand',sans-serif;
}
.app-main{
  background-color: #0078ed;
  background-image: -webkit-linear-gradient(150deg, #0078ed 0%, #7868e6 100%);
  background-image: -moz-linear-gradient(150deg, #0078ed 0%, #7868e6 100%);
  background-image: -o-linear-gradient(150deg, #0078ed 0%, #7868e6 100%);
  background-image: linear-gradient(150deg, #0078ed 0%, #7868e6 100%);
  overflow-x: hidden;
}
::selection{
  color: #0078ed;
  background: #EDEEF7;
}
::-moz-selection { /* Code for Firefox */
  color: #0078ed;
  background: #EDEEF7;
}
@media only screen and (max-width: 450px) {
  .app-main {
    overflow-x: auto;
  }
}