@import url('https://fonts.googleapis.com/css2?family=REM:wght@700&display=swap');.title-img{
    display: block;
    text-align: center;
}
.title-img > h1{
    /* HEADING */
    font-size: 3.4rem;
    font-family: 'REM', sans-serif;
    color: #EDEEF7;
    line-height: 130%;
    padding: 20px;
}
.victoria{
    max-width: 85vw;
    max-height: 85vw;
    border-radius: 8px;
    margin-top: 30px;
}
.subheading{
    color: #EDEEF7;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 2.5rem;
}

.register-ref-div{
    display: flex;
    justify-content:center ;
    background-color:transparent;
    margin: 20px;
}
.button-wrapper {
    background-color: #1e0149;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 1.2rem;
}

.aboutdiv{
    display:flex;
    justify-content: center;
}
.conf-date{
    font-weight: 500;
    background-color: #1e0149;
    color: #EDEEF7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    border-radius: 8px;
    max-width: 78vw;
    margin-left: 10.2vw;
    margin-right: 10.2vw;
    padding: 10px;
    text-align: center;
}

.sponsordiv{
    display: flex;
    justify-content: space-evenly;
    max-width: 70vw;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}
.sponsor-subdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.sponsor-subdiv > img {
    text-align: center;
    width: 10vw;
    height: 10vw;
    border: solid 2px rgb(255, 255, 255);
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
    margin: 20px;
}
.sponsor-caption {
    color: #EDEEF7;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-self: center;
    font-size: 1.2rem;
    /* margin-bottom: 30px; */
}

.view-schedule {
    color: yellow;
    text-decoration: none;
    font-size: 1.5rem;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 450px) {
    .title-img > h1{
        font-size: 2rem;
    }
    .conf-date > h2 {
        font-size: 1rem;
    }
    .victoria{
        max-width: 90vw;
        max-height: 90vw;
    }

}