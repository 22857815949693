.main-committeepage{
    display: flex;
    justify-content: center;
    align-items: center;
}
.professors{
    margin: 10px;
    max-width: 85vw;
    min-width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.h2{
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: 1.35rem;
    text-align: left;
    color: #fdafce;
    margin-top: 5px;
}
.professorsGrid {
    display: block;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    /* gap: 10px; */
    /* background-color: #000567; */
    padding:2px;
    
}
.tcp-table{
    /* it is a div that contains the table */
    display: flex;
    justify-content: center;


}
.tcp-table > table {
    max-width: 35vw;
}


.tcp-table > table > thead > tr > th{
    /* it is a cell of the table */
    color: #fdafce;
    font-weight: 500;
}

.tcp-table > table > tbody > tr > td{
    /* it is a cell of the table */
    color: #EDEEF7;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 6.5px;
}

@media screen and (max-width: 780px){
    .tcp-table > table {
        max-width: 65vw;
    }
}

@media only screen and (max-width: 450px) {
    .h2{
        font-size: 1.5rem;
    }
    .tcp-table > table {
        max-width: 90vw;
    }    
}
