.about-college{
    /*paragraph+h1 - DIV*/
    margin: 20px;
    margin-top: 30px;
    text-align:left;
    color: #EDEEF7;
    font-weight: 500;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: #1e0149;
    padding: 20px;
    border-radius: 8px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-college > div > button{
    border-radius: 8px;
    background-color: #EDEEF7;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.about-college > div > button > a {
    text-decoration: none;
    font-weight: 500;
}
.about-college > div {
    margin: 50px;
    padding: 10px;
}
@media only screen and (max-width: 450px) {
    .about-college > div > p{
        font-size: 0.95rem;
  
    }
    .about-college{
        display: block;
        margin: 20px;
    }
    .about-college > div {
        margin: 5px;
        padding: 3px;
    }
    .clock-tower{
        max-width:70vw;
        max-height: 70vw;
        border-radius: 8px;
        text-align: center;
        margin: 10px;
    }

}
