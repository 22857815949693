.table > th , td{
    border: 1px solid #EDEEF7;
    padding: 25px;
    color: #EDEEF7;
    font-weight: 500;
    font-size: 1.2rem;
}
tr > th{
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
    border: 1px solid #EDEEF7;
    padding: 10px;
}

.table{
    max-width: 80vw;
}

.table-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 50px;
}
.heading{
    display: block;
    color: #EDEEF7;
    font-size: 1.8rem;
    font-weight: 500;
}
.t-head{
    color:#EDEEF7;
    font-weight: 500;
    font-size: 0.7rem;    
}
@media only screen and (max-width: 450px) {
    .table > th , td{
        padding: 8px;
        font-size: 0.75rem;
    }
    tr > th{
        font-size: 0.5rem;
    }
    .heading{
        font-size: 1rem;
    }
}