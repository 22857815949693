.submit-main-div{
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 27vh;
}
.content-wrapper {
    max-width: 75vw;
    text-align: justify;
    color: #EDEEF7;
}

.content-wrapper > h1 {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.content-wrapper > p{
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 30px;
}
.content-wrapper > button {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
    border: none;
    margin-top:10px ;
    margin-bottom: 30px;
}
.content-wrapper > button > a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1.5rem;
}