.contact-card{
    padding: 25px;
    border-radius: 8px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: #1e0149;
    width: 40vw;
    margin: 20px;
    font-family: 'Poppins',sans-serif;
    color:#EDEEF7;
    text-align: left;
}
.name{
    font-weight: 500;
    font-size: 1.7rem;
}
.email{
    font-weight: 400;
    font-size: 1.3rem;
}
.phone{
    font-weight: 400;
    font-size: 1.3rem;
}
@media only screen and (max-width: 800px) {
    .contact-card{
        width: 80vw;
    }
    .name{
        font-size: 1.2rem;
    }
    .email{
        font-size: 0.85rem;
    }
    .phone{
        font-size: 0.85rem;
    }
}